import { Overwrite } from 'utility-types';

export enum FavoriteModelType {
  PLAN = 'WebPlan',
  TOUR = 'WebTour',
  ASSET = 'WebAsset',
}

export interface FavoriteResponse<T = any> {
  /** DateTime of creation of the session, represented as a string */
  createdAt: string;

  /** The id of the Profile */
  id: string;

  /** The type of model that is favorited */
  modelType: FavoriteModelType;

  /** Primary Id of the favorited model */
  modelId: string;

  /** The ID of the owner record */
  ownerId: string;

  /** DateTime of last update to the session, represented as a string */
  updatedAt: string;

  /** The model data */
  model: T | null;
}

export type Favorite<T = any> = Overwrite<FavoriteResponse<T>, {
  createdAt: Date;
  updatedAt: Date;
}>

export type FavoritePlanProps = { planId: string; }
export type UnfavoritePlanProps = { planId: string; }
