import { Api, withCancelToken } from '../api';
import { PipelinePhaseResponse, TimelineSchedule } from './types';
import { pipelinePhaseValidator, timelineScheduleValidator } from './utils';
import { reporter } from '../utils/error-reporter';
import { array } from 'yup';

const BASE_URL = 'api/customer/timeline';

export const TimelineApi = (api: Api) => ({
  /**
   * Fetch a specific Profile by id
   */
  fetchAll: withCancelToken((cancelToken) => async () => {
    const resp = await api.get<PipelinePhaseResponse[]>(`${BASE_URL}/phase`, { cancelToken });

    const phases = await array(pipelinePhaseValidator).validate(resp.data)
      .catch(reporter.notifyAndThrow);

    return { ...resp, data: phases };
  }),
  
  /**
   * Fetch a Timeline Phase by id
   */
  fetch: withCancelToken((cancelToken) => async (props: { id: string }) => {
    const resp = await api.get<PipelinePhaseResponse>(`${BASE_URL}/phase/${props.id}`, { cancelToken });

    const phase = await pipelinePhaseValidator.validate(resp.data)
      .catch(reporter.notifyAndThrow);

    return { ...resp, data: phase };
  }),
})
