import { Ability, RawRuleFrom, MongoQuery, AbilityClass } from '@casl/ability';
import { PackRule } from '@casl/ability/extra';
import { Overwrite } from "utility-types";

import { Profile } from '../profile';
import { CustomerProfile, CustomerProfileResponse } from '../customer-profile';
import { Session } from '../session';
import { PortalType } from '../api';

const actions = ['manage', 'create', 'read', 'update', 'destroy'] as const;
const subjects = [
  'User', 'Session', 'Profile', 'CustomerProfile',
] as const;
export type Abilities = [
  typeof actions[number],
  typeof subjects[number] | User | Session | CustomerProfile | Profile
];
export type AppAbility = Ability<Abilities>;
export const AppAbility = Ability as AbilityClass<AppAbility>;


export interface UserResponse {
  /** DateTime of creation of the session, represented as a string */
  auth: {
    local: {
      email: string;
      updatedAt: string;
      hasStalePassword: boolean;
    } | null;
  };

  /** The User's profile for the customer portal */
  customerProfile: CustomerProfileResponse | null;

  /** A range of datetimes that use account is considerd disabled */
  disabledRanges: [string, string][];
  
  /** The id of the User */
  id: string;

  /** Is the user account currently compromised */
  isCompromised: boolean;
  
  /** Is the user account currently frozen */
  isFrozen: boolean;

  /** Date the user last logged in */
  lastLoginAt: string | null;
  
  /** The portals that the user has access to */
  permissions: PackRule<RawRuleFrom<Abilities, MongoQuery>>[];

  /** The portals that the user has access to */
  portalTypes: PortalType[];

  /** The User's roles for determining permissions */
  roles: string[];
};

export type User = Overwrite<UserResponse, {
  auth: {
    local: {
      email: string;
      updatedAt: Date;
      hasStalePassword: boolean;
    } | null;
  }
  customerProfile: CustomerProfile | null;
  lastLoginAt: Date | null;
  disabledRanges: [Date, Date][];
  permissions: AppAbility;
}>
