import { object, string, array, boolean, date, mixed } from 'yup';
import {
  PipelineMilestone,
  PipelinePhase,
  ScheduleMilestone,
  SchedulePhase,
  TimelineSchedule
} from './types';

export const pipelineMilestoneValidator = object<PipelineMilestone>({
  code: string().required(),
  description: string().nullable(),
  displayCompletedAs: mixed<PipelineMilestone['displayCompletedAs']>()
    .required()
    .oneOf(['date', 'datetime', 'checkbox']),
  displayScheduledAs: mixed<PipelineMilestone['displayScheduledAs']>()
    .required()
    .oneOf(['date', 'datetime', 'checkbox']),
  id: string().required(),
  label: string().required(),
  pipelinePhaseId: string().required(),
  showCompletedDate: boolean(),
  showScheduledDate: boolean(),
})


export const pipelinePhaseValidator = object<PipelinePhase>({
  code: string().required(),
  id: string().required(),
  label: string(),
  marketingDescription: string().nullable(),
  milestones: array(pipelineMilestoneValidator),
  slug: string().required(),
  shortLabel: string(),
  status: string(),
})


export const scheduleMilestoneValidator = object<ScheduleMilestone>({
  code: string().required(),
  completed: date().nullable().notRequired(),
  description: string().nullable(),
  displayCompletedAs: mixed<PipelineMilestone['displayCompletedAs']>()
    .required()
    .oneOf(['date', 'datetime', 'checkbox']),
  displayScheduledAs: mixed<PipelineMilestone['displayScheduledAs']>()
    .required()
    .oneOf(['date', 'datetime', 'checkbox']),
  id: string().required(),
  label: string().required(),
  pipelinePhaseId: string().required(),
  scheduled: date().nullable().notRequired(),
  showCompletedDate: boolean(),
  showScheduledDate: boolean(),
})

export const schedulePhaseValidator = object<SchedulePhase>({
  code: string().required(),
  id: string().required(),
  isCurrent: boolean(),
  label: string(),
  marketingDescription: string().nullable(),
  milestones: array(scheduleMilestoneValidator),
  slug: string().required(),
  shortLabel: string(),
  status: string(),
})

export const timelineScheduleValidator = object<TimelineSchedule>({
  id: string().required(),
  homeSiteId: string().required(),
  phases: array(schedulePhaseValidator),
})