import { Api, withCancelToken } from '../api';
import { Profile, CreateProfileProps, UpdateProfileProps } from './types';
import { profileValidator } from './utils';
import { reporter } from '../utils/error-reporter';

const BASE_URL = 'api/profile';

export const ProfileApi = (api: Api) => ({
  /**
   * Fetch a specific Profile by id
   */
  fetch: withCancelToken((cancelToken) => async (props: { id: string }) => {
    const resp = await api.get<Profile>(`${BASE_URL}/${props.id}`, { cancelToken });

    const profile = await profileValidator.validate(resp.data)
      .catch(reporter.notifyAndThrow);

    return { ...resp, data: profile };
  }),

  /**
   * Create a new Profile
   */
  create: withCancelToken((cancelToken) => async (props: CreateProfileProps) => {
    const resp = await api.post<Profile>(`${BASE_URL}`, props, { cancelToken });

    const profile = await profileValidator.validate(resp.data)
      .catch(reporter.notifyAndThrow);

    return { ...resp, data: profile };
  }),

  /**
   * Update a Profile
   */
  update: withCancelToken((cancelToken) => async (props: UpdateProfileProps) => {
    const { id, ...rest } = props;
    const resp = await api.post<Profile>(`${BASE_URL}/${id}`, rest, { cancelToken });

    const profile = await profileValidator.validate(resp.data)
      .catch(reporter.notifyAndThrow);

    return { ...resp, data: profile };
  }),
})
