import { Api, withCancelToken } from '../api';
import { homeownerValidator } from './utils';
import {
  Homeowner,
  HomeownerResponse
} from './types';
import { reporter } from '../utils/error-reporter';

const BASE_URL = 'api/customer/homeowner';


export interface VerifyUserProps {
  token: string;
}

export const HomeownerApi = (api: Api) => ({
  /**
   * Fetch a specific Homeowner
   */
  fetch: withCancelToken((cancelToken) => async (props: { id: string }) => {
    const resp = await api.get<HomeownerResponse>(`${BASE_URL}/${props.id}`, { cancelToken });

    const quote = await homeownerValidator.validate(resp.data)
      .catch(reporter.notifyAndThrow);

    return { ...resp, data: quote };
  }),
  
  /**
   * Fetch all of a user's Homeowners
   */
  fetchMine: withCancelToken((cancelToken) => async() => {
    let homeowners: Homeowner[] = [];
    const resp = await api.get<HomeownerResponse[]>(BASE_URL);

    for (const quote of resp.data) {
      const validatedQuote = await homeownerValidator.validate(quote)
        .catch(reporter.notifyAndThrow)
      
      homeowners.push(validatedQuote);
    }

    return { ...resp, data: homeowners };
  }),


  /**
   * Verify a user to belong to a Homeowner
   */
  verify: withCancelToken((cancelToken) => (props: VerifyUserProps) => {
    return api.post(`${BASE_URL}/verify`, props, { cancelToken });
  }),
})
