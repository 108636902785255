// Helpers for working with the window.Location object

/**
 * Coonvert a query string (?a=foo&b=bar) to an object of key:value pairs
 */
export function queryParamsToObject(params: string) {
  return params
    .substring(1)
    .split('&')
    .reduce<Record<string, string>>((acc, value) => {
      const parts = value.split('=');
      if (parts[0]) {
        acc[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1]);
      }
      return acc;
    }, {});
}
