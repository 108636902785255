import { Api, withCancelToken } from '../api';
import { homeSiteValidator } from './utils';
import { HomeSite, HomeSiteResponse } from './types';
import { reporter } from '../utils/error-reporter';
import { TimelineSchedule, timelineScheduleValidator } from '../timeline';
const BASE_URL = 'api/customer/home-site';

export const HomeSiteApi = (api: Api) => ({
  /**
   * Fetch a specific HomeSite
   */
  fetch: withCancelToken((cancelToken) => async (props: { id: string }) => {
    const resp = await api.get<HomeSiteResponse>(
      `${BASE_URL}/${props.id}`,
      { cancelToken }
    );

    const site = await homeSiteValidator.validate(resp.data)
      .catch(reporter.notifyAndThrow);

    return { ...resp, data: site };
  }),

  /**
   * Fetch all of a users HomeSites
   */
  fetchAll: withCancelToken((cancelToken) => async () => {
    const homeSites: HomeSite[] = [];
    const resp = await api.get<HomeSiteResponse[]>(BASE_URL, { cancelToken });

    for (const site of resp.data) {
      const validatedSite = await homeSiteValidator.validate(site)
        .catch(reporter.notifyAndThrow)

      homeSites.push(validatedSite);
    }

    return { ...resp, data: homeSites }
  }),

  /**
   * Fetch a Timeline Schedule that belongs to a HomeSite
   */
  fetchHomeSiteSchedule: withCancelToken((cancelToken) => async (props: { id: string }) => {
    const resp = await api.get<TimelineSchedule>(`${BASE_URL}/${props.id}/timeline`, { cancelToken });

    const phase = await timelineScheduleValidator.validate(resp.data)
      .catch(reporter.notifyAndThrow);

    return { ...resp, data: phase };
  }),
})
