import { object, date, string, number } from 'yup';

import { Document } from './types';

export const documentValidator = object<Document>({
  contentModifiedDate: date().required(),
  contentSize: number().required(),
  createdDate: date().required(),
  description: string().nullable(),
  displayName: string().ensure(),
  documentType: string().nullable(),
  fileType: string().required(),
  id: string().required(),
  lastModifiedDate: date().required(),
  title: string().required(),
});
