import { object, string, date, array, number } from 'yup';
import { documentValidator } from '../document';
import { employeeValidator } from '../employee';
import { HomeSite } from './types';


export const homeSiteValidator = object<HomeSite>({
  administrativeProjectCoordinator: employeeValidator.nullable(),
  branchId: string().nullable(),
  constructionSuperintendent: employeeValidator.nullable(),
  createdAt: date(),
  documents: array().of(documentValidator).ensure(),
  drawAdmin: employeeValidator.nullable(),
  homeOwnershipCounselor: employeeValidator.nullable(),
  id: string(),
  mileageDistance: number(),
  name: string().nullable(),
  preConstructionAdmin: employeeValidator.nullable(),
  primaryContact: employeeValidator.nullable(),
  regionalConstructionManager: employeeValidator.nullable(),
  regionalSalesManager: employeeValidator.nullable(),
  salesTaxCode: string().nullable(),
  salesTaxRate: number().nullable(),
  seniorPreConstructionAdmin: employeeValidator.nullable(),
  siteAddress: string().nullable(),
  siteCity: string().nullable(),
  siteCounty: string().nullable(),
  siteState: string().nullable(),
  siteZipcode: string().nullable(),
  updatedAt: date(),
})
