import Axios, { AxiosResponse, CancelToken } from "axios";

export function withCancelToken<T, U extends any[] = []>(
  fn: (token: CancelToken) => (...args: U) => Promise<AxiosResponse<T>>
) {
  return (...args: U) => {
    const source = Axios.CancelToken.source();
    return {
      request: fn(source.token)(...args),
      cancel: source.cancel,
    }
  }
}