import { object, string, date } from 'yup';

import { Employee } from './types';

export const employeeValidator = object<Employee>({
  branchId: string().nullable(),
  cellPhone: string().nullable(),
  department: string().required(),
  email: string().nullable(),
  fax: string().nullable(),
  id: string().required(),
  name: string().required(),
  title: string().required(),
  workPhone: string().nullable(),
});
