// Helpers for the user session

import { decode } from 'jsonwebtoken';
import { JWT, SessionToken, Session } from './types';
import { object, string, date, boolean, array, number } from 'yup';


/**
 * Is the token past its expiration date
 */
export function isTokenExpired(token: JWT) {
  const decodedToken = decodeToken(token);
  
  if (decodedToken === null) return true;
  return Date.now() > decodedToken.exp * 1000;
}

/**
 * Decode the JWT
 */
export function decodeToken(token: string): SessionToken {
  return decode(token) as SessionToken;
}

/**
 * Validate that an object is correctly formatted as a Session
 */
export const sessionValidator = object<Session>({
  createdAt: date().required(),
  expiresAt: date().required(),
  id: string().required(),
  isExpired: boolean(),
  sessionSeconds: number().required(),
  updatedAt: date().required(),
  userId: string().required(),
})