import { object, date, string, number, boolean, array } from "yup";
import { documentValidator } from "../document";
import { homeSiteValidator } from "../home-site";
import { HomeQuote } from "./types";


export const homeQuoteValidator = object<HomeQuote>({
  agreementBalance: number().nullable(),
  basePrice: number().nullable(),
  buildType: string().nullable(),
  conceptFees: number().nullable(),
  converted: boolean(),
  createdDate: date(),
  customOptionTotal: number().nullable(),
  designFees: number().nullable(),
  documents: array().of(documentValidator).ensure(),
  elevationName: string().ensure(),
  engineeringFees: number().nullable(),
  grandTotal: number().nullable(),
  homeSite: homeSiteValidator,
  id: string(),
  lastModifiedAt: date(),
  mileageCharge: number().nullable(),
  name: string().nullable(),
  nonTaxableSubtotal: number().nullable(),
  planOrientation: string().nullable(),
  processingFees: number().nullable(),
  salesTax: number().nullable(),
  standardOptionTotal: number().nullable(),
  taxableSubtotal: number().nullable(),
  turnKeyDeposit: number().nullable(),
});
