import axios, { AxiosResponse, AxiosRequestConfig, Canceler } from 'axios';
import { Api, ApiResponse, withCancelToken } from '../api';
import { homeQuoteValidator } from './utils';
import {
  HomeQuote,
  HomeQuoteResponse
} from './types';
import { reporter } from '../utils/error-reporter';

const BASE_URL = 'api/customer/home-quote';


export const HomeQuoteApi = (api: Api) => ({
  /**
   * Fetch a specific HomeQuote
   */
  fetch: withCancelToken((cancelToken) => async (props: { id: string }) => {
    const resp = await api.get<HomeQuoteResponse>(
      `${BASE_URL}/${props.id}`,
      { cancelToken },
    );

    const quote = await homeQuoteValidator.validate(resp.data)
      .catch(reporter.notifyAndThrow);

    return { ...resp, data: quote };
  }),

  /**
   * Fetch all of a users HomeQuotes
   */
  fetchAll: withCancelToken((cancelToken) => async () => {
    let quotes: HomeQuote[] = [];
    const resp = await api.get<HomeQuoteResponse[]>(BASE_URL, { cancelToken });

    for (const quote of resp.data) {
      const validatedQuote = await homeQuoteValidator.validate(quote)
        .catch(reporter.notifyAndThrow)
        .catch((error) => {
          throw error;
        });

      quotes.push(validatedQuote);
    }

    return { ...resp, data: quotes };
  }),
})
