import Axios, { AxiosInstance } from 'axios';

import { JWT } from '../session';

export enum PortalType {
  PUBLIC = 'public',
  CORE = 'core',
  CUSTOMER = 'customer',
  PARTNER = 'partner',
  CONTRACTOR = 'contractor',
}

interface ApiProps {
  baseUrl?: string;
  token?: JWT;
  portal?: PortalType;
}

export type Api = {
  delete: AxiosInstance['delete'];
  get: AxiosInstance['get'];
  patch: AxiosInstance['patch'];
  post: AxiosInstance['post'];
  put: AxiosInstance['put'];
  request: AxiosInstance['request'];
  axios: AxiosInstance;
  setToken: (token: JWT | null) => void;
  setPortal: (portal?: PortalType) => void;
}

export const Api = (props: ApiProps = {}) => {
  const axios = Axios.create({
    headers: {
      Authorization: props.token ? `Bearer ${props.token}` : undefined,
      'X-Requesting-Portal': props.portal ?? PortalType.PUBLIC,
    },
    baseURL: props.baseUrl || '',
    withCredentials: true,
    xsrfCookieName: 'xsrfToken',
    xsrfHeaderName: 'X-CSRF-Token',
  });

  return {
    delete: axios.delete,
    get: axios.get,
    patch: axios.patch,
    post: axios.post,
    put: axios.put,
    request: axios.request,
    axios,

    setToken(token: JWT | null) {
      axios.defaults.headers.Authorization = token ? `Bearer ${token}` : null;
    },
    
    setPortal(portal: PortalType = PortalType.PUBLIC) {
      axios.defaults.headers['X-Requesting-Portal'] = portal;
    }
  }
}

export * from './types';
export * from './utils';
