import { object, date, string, mixed } from 'yup';
import { Favorite, FavoriteModelType } from './types';
import { Plan } from '../plan/types';
import { WebTour } from '../web-tour/types';
import { WebAsset } from '../web-asset/types';


export const FavoriteValidator = object<Favorite>({
  createdAt: date().required(),
  id: string().required(),
  modelId: string().required(),
  modelType: mixed().oneOf(Object.values(FavoriteModelType)).required(),
  ownerId: string().required(),
  updatedAt: date().required(),
  model: object().nullable(),
})

export function isPlanFavorite(favorite: Favorite<any>): favorite is Favorite<Plan> {
  return favorite.modelType === FavoriteModelType.PLAN;
}

export function isTourFavorite(favorite: Favorite<any>): favorite is Favorite<WebTour> {
  return favorite.modelType === FavoriteModelType.TOUR;
}

export function isAssetFavorite(favorite: Favorite<any>): favorite is Favorite<WebAsset> {
  return favorite.modelType === FavoriteModelType.ASSET;
}