import { Api, withCancelToken } from '../api';
import { ResourceFile } from './types';
import { resourceValidator } from './utils';
import { reporter } from '../utils/error-reporter';
import { array } from 'yup';

const BASE_URL = 'api/core/resource';

export const ResourceApi = (api: Api) => ({
  /**
   * Fetch a specific Profile by id
   */
  fetchAll: withCancelToken((cancelToken) => async () => {
    const resp = await api.get<ResourceFile[]>(`${BASE_URL}`, { cancelToken });

    const resources = await array(resourceValidator).validate(resp.data)
      .catch(reporter.notifyAndThrow);

    return { ...resp, data: resources };
  }),
  
  /**
   * Fetch a specific Profile by id
   */
  fetch: withCancelToken((cancelToken) => async (props: { id: string }) => {
    const resp = await api.get<ResourceFile>(`${BASE_URL}/${props.id}`, { cancelToken });

    const profile = await resourceValidator.validate(resp.data)
      .catch(reporter.notifyAndThrow);

    return { ...resp, data: profile };
  }),

})
