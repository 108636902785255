import { object, date, string, array } from 'yup';
import { ResourceFile } from './types';


export const resourceValidator = object<ResourceFile>({
  createdAt: date().required(),
  fileExtension: string().required(),
  fileType: string().nullable(),
  id: string().required(),
  previewUrl: string().nullable(),
  resourceType: string<'file'>().required().oneOf(['file']),
  timelinePhases: array().of(object({
    id: string().required(),
    slug: string().required(),
    label: string().required(),
  })).notRequired(),
  title: string().nullable(),
  updatedAt: date().required(),
  url: string().required(),
})