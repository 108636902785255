import { SessionResponse } from './types';
import { Api, withCancelToken } from '../api';
import { sessionValidator } from './utils';
import { reporter } from '../utils/error-reporter';

const BASE_URL = 'api/session';

export const SessionApi = (api: Api) => ({
  /**
   * Authenticate the user
   */
  login: withCancelToken((cancelToken) => async (params: { username: string, password: string }) => {
    const resp = await api.post<{session: SessionResponse}>(
      'api/auth/local',
      params,
      { cancelToken }
    );
    const session = await sessionValidator.validate(resp.data.session)
      .catch(reporter.notifyAndThrow)
    
    return { ...resp, data: session };
  }),

  /**
   * Destroys the users current Session
   */
  logout: withCancelToken((cancelToken) => async() => {
    return api.post('api/auth/logout', { cancelToken });
  }),

  /**
   * Fetch a session. Will either fetch a specific Session by id, or default to
   * retrieving the current session for the active user.
   */
  fetch: withCancelToken((cancelToken) => async (id?: string) => {
    let url = id ? `${BASE_URL}/${id}` : BASE_URL;
    const resp = await api.get<SessionResponse>(url, { cancelToken });

    const session = await sessionValidator.validate(resp.data)
      .catch(reporter.notifyAndThrow)
    
    return { ...resp, data: session };
  }),

  /**
   * Initiate a local registration email
   */
  initLocalRegistration: withCancelToken((cancelToken) => async (
    params: { username: string }
  ) => {
    const resp = await api.post<void>(
      'api/register/local/init',
      params,
      { cancelToken }
    );

    return resp;
  }),

  /**
   * Register a new User with local credentials
   */
  registerLocal: withCancelToken((cancelToken) => async (
    params: { token: string, password: string }
  ) => {
    const resp = await api.post<SessionResponse>(
      'api/register/local',
      params,
      { cancelToken }
    );

    const session = await sessionValidator.validate(resp.data)
      .catch(reporter.notifyAndThrow)
    
    return { ...resp, data: session };
  }),

   /**
   * Initiate a password reset email
   */
  initPasswordReset: withCancelToken((cancelToken) => async (
    params: { username: string }
  ) => {
    const resp = await api.post<void>(
      'api/auth/local/password-reset/init',
      params,
      { cancelToken }
    );

    return resp;
  }),

  /**
   * Initiate a password reset email
   */
  resetPassword: withCancelToken((cancelToken) => async (
    params: { password: string, token: string }
  ) => {
    const resp = await api.post<void>(
      'api/auth/local/password-reset',
      params,
      { cancelToken }
    );

    return resp;
  }),
})
