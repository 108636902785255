import { Api, withCancelToken } from '../api';
import { customerProfileValidator } from './utils';
import {
  CreateCustomerProfileProps,
  UpdateCustomerProfileProps,
  CustomerProfileResponse,
  UpdateCustomerProfileSettingsProps,
} from './types';
import { reporter } from '../utils/error-reporter';
import { array } from 'yup';

const BASE_URL = 'api/customer/profile';

export const CustomerProfileApi = (api: Api) => ({
  /**
   * Fetch a specific Profile
   */
  fetch: withCancelToken((cancelToken) => async (props: { id: string }) => {
    const resp = await api.get<CustomerProfileResponse>(
      `${BASE_URL}/${props.id}`,
      { cancelToken },
    );

    const profile = await customerProfileValidator.validate(resp.data)
      .catch(reporter.notifyAndThrow);

    return { ...resp, data: profile };
  }),
  
  /**
   * Fetch all Profiles
   */
  fetchAll: withCancelToken((cancelToken) => async () => {
    const resp = await api.get<CustomerProfileResponse[]>(
      BASE_URL,
      { cancelToken },
    );

    const profiles = await array(customerProfileValidator).validate(resp.data)
      .catch(reporter.notifyAndThrow);

    return { ...resp, data: profiles };
  }),

  /**
   * Create a new Profile
   */
  create: withCancelToken((cancelToken) => async (props: CreateCustomerProfileProps) => {
    const resp = await api.post<CustomerProfileResponse>(
      `${BASE_URL}`,
      props,
      { cancelToken }
    );

    const profile = await customerProfileValidator.validate(resp.data)
      .catch(reporter.notifyAndThrow);

    return { ...resp, data: profile };
  }),

  /**
   * Update a Profile
   */
  update: withCancelToken((cancelToken) => async (props: UpdateCustomerProfileProps) => {
    const { id, ...rest } = props;
    const resp = await api.post<CustomerProfileResponse>(
      `${BASE_URL}/${id}`,
      rest,
      { cancelToken },
    );

    const profile = await customerProfileValidator.validate(resp.data)
      .catch(reporter.notifyAndThrow);

    return { ...resp, data: profile };
  }),
  
  /**
   * Update a Profile
   */
  updateSettings: withCancelToken((cancelToken) => async (props: UpdateCustomerProfileSettingsProps) => {
    const { id, ...rest } = props;
    const resp = await api.post<CustomerProfileResponse>(
      `${BASE_URL}/${id}/settings`,
      rest,
      { cancelToken },
    );

    const profile = await customerProfileValidator.validate(resp.data)
      .catch(reporter.notifyAndThrow);

    return { ...resp, data: profile };
  }),
})
