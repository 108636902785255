import { object, date, string } from "yup";
import { Profile } from "./types";


export const profileValidator = object<Profile>({
  createdAt: date().required(),
  firstName: string().required(),
  fullName: string().required(),
  lastName: string().required(),
  id: string().required(),
  settings: object(),
  ownerId: string().required(),
  ownerType: string<'user'>().required().oneOf(['user']),
  updatedAt: date().required(),
  userId: string().required(),
})