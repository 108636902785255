import { Api, withCancelToken } from '../api';
import {  FavoriteResponse } from './types';
import { FavoriteValidator } from './utils';
import { reporter } from '../utils/error-reporter';
import { array } from 'yup';
import { Plan } from '../plan/types';
import { WebTour } from '../web-tour/types';
import { WebAsset } from '../web-asset/types';

const BASE_URL = 'api/customer/favorite';

export const FavoriteApi = (api: Api) => ({
  /**
   * Fetch a users favorites
   */
  fetchAll: withCancelToken((cancelToken) => async () => {
    const resp = await api.get<FavoriteResponse[]>(BASE_URL, { cancelToken });

    const profile = await array(FavoriteValidator).validate(resp.data)
      .catch(reporter.notifyAndThrow);

    return { ...resp, data: profile };
  }),

  /**
   * Favorite a Plan
   */
  favoritePlan: withCancelToken((cancelToken) => async (planId: string) => {
    const resp = await api.post<FavoriteResponse<Plan>>(`${BASE_URL}/plan`, { data: { planId }, cancelToken });

    return resp;
  }),

  /**
   * Unfavorite a Plan
   */
  unfavoritePlan: withCancelToken((cancelToken) => async (planId: string) => {
    const resp = await api.delete<void>(`${BASE_URL}/plan`, { data: { planId }, cancelToken });

    return resp;
  }),
  
  /**
   * Favorite a Tour
   */
  favoriteTour: withCancelToken((cancelToken) => async (tourId: string) => {
    const resp = await api.post<FavoriteResponse<WebTour>>(`${BASE_URL}/tour`, { data: { tourId }, cancelToken });

    return resp;
  }),

  /**
   * Unfavorite a Tour
   */
  unfavoriteTour: withCancelToken((cancelToken) => async (tourId: string) => {
    const resp = await api.delete<void>(`${BASE_URL}/tour`, { data: { tourId }, cancelToken });

    return resp;
  }),
  
  /**
   * Favorite an Asset
   */
  favoriteAsset: withCancelToken((cancelToken) => async (assetId: string) => {
    const resp = await api.post<FavoriteResponse<WebAsset>>(`${BASE_URL}/asset`, { data: { assetId }, cancelToken });

    return resp;
  }),

  /**
   * Unfavorite an Asset
   */
  unfavoriteAsset: withCancelToken((cancelToken) => async (assetId: string) => {
    const resp = await api.delete<void>(`${BASE_URL}/asset`, { data: { assetId }, cancelToken });

    return resp;
  }),

  /**
   * Delete Favorite
   */
  delete: withCancelToken((cancelToken) => async (favoriteId: string) => {
    const resp = await api.delete<void>(`${BASE_URL}/${favoriteId}`, { cancelToken });

    return resp;
  }),
})
