import { UserResponse } from './types';
import { Api, withCancelToken } from '../api';
import { userValidator } from "./utils";
import { reporter } from '../utils/error-reporter';

const BASE_URL = 'api/user';

export interface FetchUserProps {
  id: string;
}

export interface DeleteUserProps {
  id: string;
}

export const UserApi = (api: Api) => ({
  /**
   * Fetch a specific user.
   */
  fetch: withCancelToken((cancelToken) => async (options: FetchUserProps) => {
    const resp = await api.get<UserResponse>(`${BASE_URL}/${options.id}`, { cancelToken });

    const user = await userValidator.validate(resp.data)
      .catch(reporter.notifyAndThrow)

    return { ...resp, data: user };
  }),

  /**
   * Fetch all users.
   */
  fetchAll: withCancelToken((cancelToken) => async () => {
    const resp = await api.get<UserResponse[]>(BASE_URL, { cancelToken });

    const users = await Promise.all(resp.data.map((userResp) => {
      return userValidator.validate(userResp);
    })).catch(reporter.notifyAndThrow)

    return { ...resp, data: users };
  }),

  /**
   * Delete a user.
   */
  delete: withCancelToken((cancelToken) => async (options: DeleteUserProps) => {
    return api.delete(`${BASE_URL}/${options.id}`, { cancelToken });
  }),

  /**
   * Update the active user's LocalAuth password.
   */
  updatePassword: async ( options: { password: string; newPassword: string }) => {
    return api.post<void>(`api/auth/local/password-update`, options);
  },
})
