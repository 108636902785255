import { Notifier } from '@airbrake/browser';

export class CustomNotifier extends Notifier {
  notifyAndThrow = async (error: any): Promise<never> => {
    await this.notify(error);
    throw error;
  }
}

export function createReporter(id: number, key: string, environment: string = 'production') {
  return new CustomNotifier({
    projectId: id,
    projectKey: key,
    environment: environment,
  });
}