import { User, AppAbility } from './types';
import { unpackRules } from '@casl/ability/extra';
import { object, string, array, boolean, date, mixed } from 'yup';

import { customerProfileValidator } from '../customer-profile';


export const userValidator = object<User>({
  auth: object({
    local: object({
      email: string().required(),
      updatedAt: date(),
      hasStalePassword: boolean(),
    }).nullable(),
  }),
  disabledRanges: array<[Date, Date]>(mixed()).ensure(),
  id: string().required(),
  isCompromised: boolean(),
  isFrozen: boolean(),
  lastLoginAt: date().nullable(),
  permissions: mixed<AppAbility>()
    .transform(function (value) {
      return new AppAbility(unpackRules(value));
    }),
  portalTypes: array(string()),
  customerProfile: customerProfileValidator.nullable(),
  roles: array(string()),
});
