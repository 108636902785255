import { object, string, boolean } from 'yup';
import { WebDevelopment } from './types';


export const WebDevelopmentValidator = object<WebDevelopment>({
  id: string().required(),
  isActive: boolean().required(),
  name: string().required(),
  state: string().required(),
  ihmsCompanyCode: string().notRequired(),
  ihmsDevelopmentCode: string().notRequired(),
});
