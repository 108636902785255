import { object, string, date } from 'yup';
import { Homeowner } from './types';


export const homeownerValidator = object<Homeowner>({
  createdAt: date(),
  customerProfileId: string().nullable(),
  householdSfId: string().nullable(),
  id: string(),
  name: string().nullable(),
  salesforceId: string(),
  updatedAt: date(),
});
