import { object, string, boolean } from 'yup';
import { WebDevelopmentValidator } from '../web-development/util';
import { CustomerProfile } from './types';


export const customerProfileValidator = object<CustomerProfile>({
  email: string().nullable(),
  firstName: string().nullable(),
  fullName: string().ensure(),
  id: string().required(),
  isEmailVerified: boolean().required(),
  lastName: string().nullable(),
  settings: object({
    location: object({
      companyCode: string().required(),
      developmentCode: string().required(),
    }).notRequired().default(undefined),
    locationDevelopment: WebDevelopmentValidator.nullable(),
  }),
  termsAccepted: boolean().required(),
  userId: string().required(),
});
