import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Canceler, AxiosResponse } from 'axios';
import { ApiResponse } from '../api';

export type ApiRequest<T = any> = (...args: any) => ApiResponse<Promise<AxiosResponse<T>>>;
type ExtractPromise<P> = P extends Promise<infer T> ? T : never;
type ExtractApiData<P> = P extends ApiResponse<infer T> ? ExtractPromise<T> : never;

export function useApi<T extends ApiRequest>(
  fn: T,
  ...options: Parameters<T>
): {
  isLoading: boolean;
  data: null | ExtractApiData<ReturnType<T>>['data'];
  error: any;
  refetch: VoidFunction;
} {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<null | ExtractApiData<ReturnType<T>>['data']>(null);
  const [error, setError] = useState<any>();
  const CancelRef = useRef<Canceler>();
  const prevOptionsRef = useRef(options);
  const optionHash = JSON.stringify(options);
  
  useEffect(() => {
    prevOptionsRef.current = options;
  });

  const executeFetch = React.useCallback(async () => {
    setIsLoading(true);
    try {
      const { cancel, request } = fn(...options);
      CancelRef.current = cancel;
      const resp = await request;
      setData(resp.data);
      setError(undefined);
    } catch(err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  }, [fn, optionHash]);
  
  const cancelOutstandingRequest = React.useCallback(() => {
    if (CancelRef.current) {
      CancelRef.current();
    }
  }, []);

  useEffect(() => {
    if (typeof fn === 'function') {
      executeFetch();
    } else {
      setData(null);
      setError(new Error('Invalid function'));
    }

    return cancelOutstandingRequest;
  }, [fn, optionHash])

  const refetch = useCallback(() => {
    cancelOutstandingRequest();
    if (typeof fn === 'function') {
      executeFetch();
    } else {
      setData(null);
      setError(new Error('Invalid function'));
    }
  }, [fn, optionHash]);

  return { isLoading, data, error, refetch };
}
