import { Api, withCancelToken } from '../api';
import { Document } from './types';
import { documentValidator } from './utils';
import { reporter } from '../utils/error-reporter';

const BASE_URL = 'api/core/document';

interface DocumentDownload {
  downloadUrl: string;
  expiresAt: string
  viewUrl: string;
}

export const DocumentApi = (api: Api) => ({
  /**
   * Fetch a specific Document by id
   */
  fetch: withCancelToken((cancelToken) => async (props: { id: string }) => {
    const resp = await api.get<Document>(`${BASE_URL}/${props.id}`, { cancelToken });

    const document = await documentValidator.validate(resp.data)
      .catch(reporter.notifyAndThrow);

    return { ...resp, data: document };
  }),

  /**
   * Download a document
   */
  view: async (id: string, filename?: string) => {
    const viewWindow = window.open('about:blank', '_blank');
    const resp = await api.get<DocumentDownload>(`${BASE_URL}/${id}/download`, { params: { filename } });

    if (!viewWindow || viewWindow.closed || typeof viewWindow.closed == 'undefined') {
      alert('Looks like your browser is blocking popups required to view documents.');
    } else {
      viewWindow.location.href = resp.data.viewUrl;
    }
  },

  /**
   * Download a document
   */
  download: async (id: string, filename?: string) => {
    const downloadWindow = window.open('about:blank', '_blank');
    const resp = await api.get<DocumentDownload>(`${BASE_URL}/${id}/download`, { params: { filename } });

    if (!downloadWindow || downloadWindow.closed || typeof downloadWindow.closed == 'undefined') {
      alert('Looks like your browser is blocking popups required to download documents.');
    } else {
      downloadWindow.location.href = resp.data.downloadUrl;
    }
  },
})
