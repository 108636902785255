import { createReporter, CustomNotifier } from '../reporter/reporter';

export class InternalNotifier {
  private notifier: CustomNotifier | undefined;

  constructor(id?: number, key?: string, environment?: string) {
    if (id && key) {
      this.notifier = createReporter(id, key, environment);
    }
  }

  notify = async (error: any) => {
    return this.notifier?.notify(error);
  }

  notifyAndThrow = async (error: any): Promise<never> => {
    if (this.notifier) {
      return this.notifier.notifyAndThrow(error)
    }

    throw error[0];
  }
}

/**
 * Sets up the internal error reporter with airbrake credentials
 * 
 * @param id Airbrake project id
 * @param key Airbrake auth key
 */
export function setupErrorReporting(id: number, key: string, environment?: string) {
  reporter = new InternalNotifier(id, key, environment);
}

export let reporter = new InternalNotifier();
